import React from "react";

import { MsalProvider } from "@azure/msal-react";
import "./styles/globals.css";
import { Splash } from "./Splash";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <QueryClientProvider client={queryClient}>
        <Splash />
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default App;
