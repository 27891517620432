import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "components/ui/collapsible";
import { ChevronRight } from "lucide-react";

import { Checkbox } from "components/ui/checkbox";
import { PlotFilterOptions } from "./props";
import { LocationAnalytes, useLabData } from "../../../context/lab-data";
import { useMemo } from "react";
import { Label } from "../../../components/ui/label";
import { ScrollArea } from "components/ui/scroll-area";
import { Button } from "../../../components/ui/button";

interface PlotFilterProps {
  plotFilterOptions: PlotFilterOptions;
  setPlotFilterOptions: (filter: PlotFilterOptions) => void;
}

export function PlotFilter({ plotFilterOptions, setPlotFilterOptions }: PlotFilterProps) {
  const { locations } = useLabData();
  const { locationOptions, analyteOptions } = useMemo(
    () => buildFilterableOptions(locations),
    [locations]
  );
  return (
    <Card>
      <Collapsible defaultOpen={true} title={"Filters"} className={"group/collapsible"}>
        <CardHeader className={"flex flex-row justify-between p-2"}>
          <CardTitle className={"text-start"}>Filters</CardTitle>
          <CollapsibleTrigger>
            <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
          </CollapsibleTrigger>
        </CardHeader>
        <CollapsibleContent>
          <CardContent className={"grid grid-cols-2 gap-1 lg:gap-2"}>
            <FilterableItem
              name={"Locations"}
              options={locationOptions}
              hiddenOptions={plotFilterOptions.hiddenLocations}
              setHiddenOptions={(hiddenLocations) =>
                setPlotFilterOptions({ ...plotFilterOptions, hiddenLocations })
              }
            />
            <FilterableItem
              name={"Analytes"}
              options={analyteOptions}
              hiddenOptions={plotFilterOptions.hiddenAnalytes}
              setHiddenOptions={(hiddenAnalytes) =>
                setPlotFilterOptions({ ...plotFilterOptions, hiddenAnalytes })
              }
            />
          </CardContent>
        </CollapsibleContent>
      </Collapsible>
    </Card>
  );
}

interface FilterableOptions {
  locationOptions: string[];
  analyteOptions: string[];
}

function buildFilterableOptions(locations?: LocationAnalytes): FilterableOptions {
  const locationOptions = Object.keys(locations ?? {}).sort();
  const analyteOptions = Array.from(
    Object.values(locations ?? {}).reduce(
      (acc, location) => new Set([...acc, ...Object.keys(location)]),
      new Set<string>()
    )
  ).sort();

  return {
    locationOptions,
    analyteOptions,
  };
}

interface FilterableItemProps {
  name: string;
  options: string[];
  hiddenOptions: string[];
  setHiddenOptions: (hiddenOptions: string[]) => void;
}

export function FilterableItem({
  name,
  options,
  hiddenOptions,
  setHiddenOptions,
}: FilterableItemProps) {
  function hideAll() {
    setHiddenOptions(options);
  }

  function showAll() {
    setHiddenOptions([]);
  }

  return (
    <div>
      <div className={"flex flex-col"}>
        <p className="text-md font-bold">{name}</p>
        <div className={"flex flex-row"}>
            <Button onClick={hideAll} variant={"ghost"}>
            Hide All
        </Button>
        <Button onClick={showAll} variant={"ghost"}>
          Show All
        </Button>
        </div>
      </div>
      <HideOptions
        options={options}
        hiddenOptions={hiddenOptions}
        setHiddenOptions={setHiddenOptions}
      />
    </div>
  );
}

function HideOptions({
  options,
  hiddenOptions,
  setHiddenOptions,
}: Omit<FilterableItemProps, "name">) {
  return (
    <ScrollArea className="h-72 w-full rounded-md border">
      <div className="p-4 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {options.map((option) => (
          <div key={option} className="text-sm">
            <Checkbox
              checked={!hiddenOptions.includes(option)}
              onCheckedChange={(checked) =>
                setHiddenOptions(
                  checked
                    ? hiddenOptions.filter((hiddenOption) => hiddenOption !== option)
                    : [...hiddenOptions, option]
                )
              }
            />
            <Label>{option}</Label>
          </div>
        ))}
      </div>
    </ScrollArea>
  );
}
