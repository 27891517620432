import { LocationAnalytes, useLabData } from "../../context/lab-data";
import { deleteSample, Sample } from "../../handlers/samples";
import { useMemo, useState } from "react";
import { DataTable } from "../../components/DataTable/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { TrashIcon } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";

dayjs.extend(utc);
dayjs.extend(timezone);

export function LabDataTable() {
  const [deletedSamples, setDeletedSamples] = useState<Sample[]>([]);

  function onDeleteSample(sample: Sample) {
    setDeletedSamples([...deletedSamples, sample]);
  }

  const { locations } = useLabData();
  const tableData = useMemo(
    () => toTableData(deletedSamples, locations),
    [locations, deletedSamples]
  );
  return <DataTable columns={columns(onDeleteSample)} data={tableData} paginated={true} />;
}

function toTableData(deletedSamples: Sample[], locations?: LocationAnalytes): Sample[] {
  if (!locations) {
    return [];
  }
  return Object.values(locations)
    .reduce((acc, location) => {
      const analyteSamples = Object.values(location).reduce((acc, analyte) => {
        return [...acc, ...analyte];
      }, [] as Sample[]);
      return [...acc, ...analyteSamples];
    }, [] as Sample[])
    .sort((a, b) => dayjs(b.collectDT).diff(dayjs(a.collectDT)))
    .filter((sample) => !deletedSamples.includes(sample));
}

function columns(onDeleteSample: (sample: Sample) => void): ColumnDef<Sample>[] {
  return [
    {
      header: "Time",
      accessorFn: ({ collectDT }) => dayjs.utc(collectDT).local().format("DD MMM YYYY hh:mm a"),
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      header: "Sample Location",
      accessorKey: "sampleLocation",
      enableSorting: true,
      enableColumnFilter: true,
    },
    {
      header: "Analyte",
      accessorKey: "analyte",
      enableSorting: true,
      enableColumnFilter: true,
    },
    {
      header: "Value",
      accessorKey: "resultValue",
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      id: "actions",
      cell: ({ row }) => <DeleteSampleMenu sample={row.original} onDeleteSample={onDeleteSample} />,
    },
  ];
}

interface DeleteSampleMenuProps {
  sample: Sample;
  onDeleteSample: (sample: Sample) => void;
}

function DeleteSampleMenu({ sample, onDeleteSample }: DeleteSampleMenuProps) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <TrashIcon className="h-4 w-4 mr-2" />
      </AlertDialogTrigger>
      <DeleteSampleAlertDialog sample={sample} onDeleteSample={onDeleteSample} />
    </AlertDialog>
  );
}

function DeleteSampleAlertDialog({ sample, onDeleteSample }: DeleteSampleMenuProps) {
  async function handleDeleteSample() {
    await deleteSample(sample);
    onDeleteSample(sample);
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Are you absolutely you want to delete this sample?</AlertDialogTitle>
        <AlertDialogDescription>
          This action cannot be undone. This will permanently delete your account and remove your
          data from our servers.
        </AlertDialogDescription>
        <pre className={"text-sm"}>{JSON.stringify(sample, null, 2)}</pre>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <AlertDialogAction onClick={handleDeleteSample}>Delete</AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}
