import { LabSheetPage } from "./LabSheetPage";

const rows: [string, string][] = [
  ["Pr Pulp Water", "% Sugar"],
  ["Lime Flume", "% Sugar"],
  ["Mol Produced", "Purity"],
  ["Main Sewer", "% Sugar"],
];
export const LossesPage = () => {
  return <LabSheetPage sampleLocations={["Pr Pulp Water", "Lime Flume", "Mol Produced", "Main Sewer"]}
                       analytes={["% Sugar", "Purity"]} rows={rows} />;
};
