import { useState } from "react";
import Form from "../Form/Form";
import FormTextField from "../Form/FormTextField";
import FormDateField from "../Form/FormDateField";
import {
  createDowntime,
  Downtime,
  DowntimeCategory,
  DowntimeSchema,
} from "../../handlers/downtime";
import FormSelectField from "../Form/FormSelectField";
import Box from "@mui/material/Box";
import { useCostCenters } from "../../hooks/useCostCenters";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

type DowntimeFormProps = {
  variant: "standard" | "filled" | "outlined";
};

const DowntimeForm = ({ variant }: DowntimeFormProps) => {
  const costCenters = useCostCenters();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [timeOfSubmission, setTimeOfSubmission] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: Downtime) => {
    setLoading(true);
    await createDowntime(data);
    setLoading(false);
    setHasSubmitted(true);
    setTimeOfSubmission(new Date());
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Downtime>({
    resolver: zodResolver(DowntimeSchema),
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} hasSubmitted={hasSubmitted} timeOfSubmission={timeOfSubmission}
          loading={loading}>
      <FormTextField variant={variant} type={"required"} label={"Reporter"} name={"Reporter"} required
                     register={register} error={errors.Reporter !== undefined} helperText={errors.Reporter?.message} />

      <FormSelectField variant={variant} label={"Cost Center"} register={register} name={"CostCenter"}
                       options={costCenters.map(cc => `${cc.id} - ${cc.name}`)}
                       required
                       error={errors.CostCenter !== undefined} helperText={errors.CostCenter?.message}
      />
      <FormSelectField variant={variant} label={"Category"} register={register} name={"Category"}
                       options={Object.values(DowntimeCategory)}
                       required
                       error={errors.Category !== undefined} helperText={errors.Category?.message}
      />

      <FormTextField variant={variant} type={"required"} required label={"Equipment"} register={register}
                     name={"Equipment"}
                     error={errors.Equipment !== undefined} helperText={errors.Equipment?.message}
      />
      <Box>

        <Controller name={"StartTime"} control={control} render={(
          { field: { onChange, value } },
        ) => (
          <FormDateField label={"Start Time"} value={value}
                         required
                         onChange={onChange} error={errors.StartTime !== undefined}
                         helperText={errors.StartTime?.message} />
        )}
        />
        <Controller control={control} name={"EndTime"} render={(
          { field: { onChange, value } },
        ) => (
          <FormDateField label={"End Time"} value={value}
                         onChange={onChange}
                         error={errors.EndTime !== undefined}
                         helperText={errors.EndTime?.message}
          />
        )} />

      </Box>
      <Box>
        <FormTextField variant={variant} type={"number"} label={"Lost Slice (hours)"}
                       register={register} name={"LostSlice"}
                       required
                       error={errors.LostSlice !== undefined} helperText={errors.LostSlice?.message}
        />
        <FormTextField variant={variant} type={"number"} label={"Lost Sugar Production (hours)"}
                       register={register} name={"LostSugarProduction"}
                       required
                       error={errors.LostSugarProduction !== undefined} helperText={errors.LostSugarProduction?.message}
        />
      </Box>

      <FormTextField variant={variant} type={"required"} label={"Reason"} required register={register} name={"Reason"}
                     error={errors.Reason !== undefined} helperText={errors.Reason?.message}
                     multiline />
    </Form>
  );
};

export default DowntimeForm;
