import { useState, useEffect } from "react";


export function useListData<Type>(getter: () => Promise<Type[] | Error>) {
  const [listData, setListData] = useState<Type[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getter();
      if (data instanceof Error) {
        console.error("Error fetching downtimes:", data);
        return [];
      }
      return data;
    };
    fetchData().then(r => setListData(r));
  }, []);
  return listData;
}
