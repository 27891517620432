// noinspection JSValidateTypes

import { Box, InputLabel, Select } from "@mui/material";
import AddRows from "./AddRows";
import React from "react";
import sampleLocations from "../../layouts/dashboards/LabDash/SampleLocations";
import MenuItem from "@mui/material/MenuItem";
import { getAnalyteOptions } from "../../handlers/samples";
import dayjs from "dayjs";
import FormDateField from "../Form/FormDateField";

export default function AutofillForm({ addRows }) {
  const [availableAnalyteOptions, setAnalyteOptions] = React.useState([]);

  const [rowData, setRowData] = React.useState({
    sampleLocation: sampleLocations[0],
    analytes: [],
    sampleTestDT: new Date(),
    resultValue: "",
  });

  const handleSampleLocationChange = (event) => {
    setAnalyteOptions(getAnalyteOptions(event.target.value));
    const filteredAnalytes = rowData.analytes.filter((analyte) => availableAnalyteOptions.includes(analyte));
    setRowData({
      ...rowData,
      sampleLocation: event.target.value,
      analytes: filteredAnalytes,
    });
  };

  const handleAnalyteChange = (event) => {
    setRowData({
      ...rowData,
      analytes: event.target.value,
    });
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        padding: "10px",
        border: "1px solid lightgray",
        borderRadius: "5px",
      }}
    >
      <Box>
        <InputLabel>Sample Location</InputLabel>
        <Select
          variant="outlined"
          style={{ width: "200px" }}
          value={rowData.sampleLocation}
          onChange={handleSampleLocationChange}
          label={"Sample Location"}
        >
          {sampleLocations.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel>Analyte</InputLabel>
        <Select
          variant="outlined"
          style={{ width: "200px" }}
          value={rowData.analytes}
          onChange={handleAnalyteChange}
          label={"Analyte"}
          multiple
        >
          {availableAnalyteOptions.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <FormDateField
          label={"Sample Test Time"}
          value={dayjs(rowData.sampleTestDT)}
          onChange={(date) => date && setRowData({ ...rowData, sampleTestDT: date.toDate() })}
        />
      </Box>
      <AddRows addRows={addRows} rowData={rowData} />
    </Box>
  );
}
