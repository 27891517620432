import React from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import VeslLogo from "../../assets/images/vesl_logo_black_font_transparent.png";
import SignInSignOutButton from "components/MSAL/SignInSignOutButton";

function LandingPageNavBar() {
  const handleSignUpClick = () => {
    window.location.href = "https://forms.office.com/r/NB8pUYy33r";
  };

  const handleForgotPWClick = () => {
    window.location.href = "https://forms.office.com/r/tHtzibMdzL";
  };

  return (
    <AppBar style={{ backgroundColor: "white", position: "fixed", top: 0 }}>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <img src={VeslLogo} alt="Vesl Logo" style={{ height: "30px" }} />
        </Typography>

        <div>
          <div className="selectors-box">
            <button className="sign-button" onClick={handleSignUpClick}>
              {" "}
              Sign Up{" "}
            </button>
            <div style={{ margin: "5px" }}></div>
            <SignInSignOutButton />
            <div style={{ margin: "5px" }}></div>
            <button className="sign-button" onClick={handleForgotPWClick}>
              {" "}
              Forgot Password{" "}
            </button>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default LandingPageNavBar;
