import { analyteOptions, SampleFilter } from "handlers/samples";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { useState } from "react";
import { Button } from "../../components/ui/button";
import MultipleSelector, { Option } from "../../components/ui/multi-select";
import { Label } from "../../components/ui/label";
import locations from "../../data/locations.json";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/ui/collapsible";
import { ChevronRight } from "lucide-react";
import { DatePickerWithRange } from "../../components/ui/date-range-picker";
import dayjs, { Dayjs } from "dayjs";

interface FilterToolbarProps {
  defaultOpen?: boolean;
  filter: SampleFilter;
  setFilter: (filter: SampleFilter) => void;
}

export function FilterToolbar({ defaultOpen, filter, setFilter }: FilterToolbarProps) {
  const [localFilter, setLocalFilter] = useState(filter);
  const [hasChanged, setHasChanged] = useState(false);

  function submitFilter() {
    setFilter(localFilter);
    setHasChanged(false);
  }

  function updateLocations(locations: string[]) {
    setLocalFilter({
      ...localFilter,
      sampleLocations: locations,
    });
    setHasChanged(true);
  }

  function updateAnalytes(analytes: string[]) {
    setLocalFilter({
      ...localFilter,
      analytes,
    });
    setHasChanged(true);
  }

  return (
    <Card>
      <Collapsible defaultOpen={defaultOpen} title={"filter"} className={"group/collapsible"}>
        <CardHeader className={"flex flex-row justify-between"}>
          <CardTitle className={"text-start"}>Query Builder</CardTitle>
          <CollapsibleTrigger>
            <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
          </CollapsibleTrigger>
        </CardHeader>
        <CollapsibleContent>
          <CardContent>
            <div className={"grid md:grid-cols-3 grid-cols-2 gap-1 lg:gap-2"}>
              <div className={"flex flex-col space-y-0.5"}>
                <Label>Locations</Label>
                <SelectableFilterItem
                  options={locations}
                  value={localFilter.sampleLocations || []}
                  onChange={updateLocations}
                  fieldName="Locations"
                />
              </div>
              <div className={"flex flex-col space-y-0.5"}>
                <Label>Analytes</Label>
                <SelectableFilterItem
                  options={analyteOptions()}
                  value={localFilter.analytes || []}
                  onChange={updateAnalytes}
                  fieldName="Analytes"
                />
              </div>
              <div className={"flex flex-col space-y-0.5"}>
                <Label>Date Range</Label>
                <DatePickerWithRange
                  date={{
                    from:
                      localFilter.startDate === undefined
                        ? undefined
                        : localFilter.startDate.toDate(),
                    to:
                      localFilter.endDate === undefined ? undefined : localFilter.endDate.toDate(),
                  }}
                  setDate={(date) => {
                    if (!date) {
                      setLocalFilter({
                        ...localFilter,
                        startDate: undefined,
                        endDate: undefined,
                      });
                      return;
                    }
                    setLocalFilter({
                      ...localFilter,
                      startDate: date.from ? dayjs(date.from) : undefined,
                      endDate: date.to ? updateEndDate(dayjs(date.to)) : undefined,
                    });
                    setHasChanged(true);
                  }}
                />
              </div>
            </div>
          </CardContent>
          <CardFooter>
            <Button onClick={submitFilter} disabled={!hasChanged}>
              Run Query
            </Button>
          </CardFooter>
        </CollapsibleContent>
      </Collapsible>
    </Card>
  );
}

interface ScrollableSelectProps {
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
  fieldName: string;
}

function SelectableFilterItem({ options, value, onChange, fieldName }: ScrollableSelectProps) {
  function handleChange(newValue: Option[]) {
    onChange(newValue.map((option) => option.value));
  }

  return (
    <MultipleSelector
      value={multiSelectOptions(value)}
      onChange={handleChange}
      options={multiSelectOptions(options)}
      placeholder={value.length === 0 ? fieldName : undefined}
    />
  );
}

function multiSelectOptions(options: string[]): Option[] {
  return options.map((option) => ({
    label: option,
    value: option,
  }));
}

function updateEndDate(d: Dayjs): Dayjs {
  // if d is today, then set time to now
  if (d.isSame(dayjs(), "day")) {
    return dayjs();
  }
  // otherwise, set time to end of day
  return d.endOf("day");
}
