import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { DerivedTag, Tag, TagEquipment, TagUnit } from "../model/tags";
import { Datum, useLiveOpcData } from "../handlers/opcData";
import { intLocalStorage } from "../lib/local-storage";

interface DashboardData {
  timeFrameMin: number;
  data: Record<string, Datum[]>;
}

interface SetTimeFramePayload {
  timeFrameMin: number;
}

type DashboardAction =
  | {
      type: "setTimeFrame";
      payload: SetTimeFramePayload;
    }
  | {
      type: "updateData";
      payload: Record<string, Datum[]>;
    };

const DashboardContext = createContext<DashboardData | null>(null);

const DashboardDispatchContext = createContext<Dispatch<DashboardAction> | null>(null);

interface DashboardProviderProps {
  children: ReactNode;
  initialTimeFrameMin: number;
  units: TagUnit[];
}

function tagsFromEquipment(equipment: TagEquipment[]): (Tag | DerivedTag)[] {
  return equipment.reduce((acc, eq) => {
    return [...acc, ...eq.tags];
  }, [] as (Tag | DerivedTag)[]);
}

function tagsFromUnits(units: TagUnit[]): (Tag | DerivedTag)[] {
  return units.reduce(
    (acc, unit) => [...acc, ...tagsFromEquipment(unit.equipment)],
    [] as (Tag | DerivedTag)[]
  );
}

export function DashboardProvider({
  initialTimeFrameMin,
  children,
  units,
}: DashboardProviderProps) {
  const [dashboardData, dispatch] = useReducer(dashboardReducer, {
    timeFrameMin: initialTimeFrameMin,
    data: {},
  });
  const opcDataProps = useMemo(
    () => ({
      tags: tagsFromUnits(units),
      windowMin: dashboardData.timeFrameMin,
    }),
    [units, dashboardData.timeFrameMin]
  );
  const { data } = useLiveOpcData(opcDataProps);

  return (
    <DashboardContext.Provider value={{ ...dashboardData, data: data ? data : {} }}>
      <DashboardDispatchContext.Provider value={dispatch}>
        {children}
      </DashboardDispatchContext.Provider>
    </DashboardContext.Provider>
  );
}

export function useDashboardData(ref: string) {
  const ctx = useContext(DashboardContext);
  if (!ctx) {
    throw new Error("useDashboardData must be used within a DashboardProvider");
  }
  return useMemo(
    () => ({
      ...ctx,
      data: ctx.data[ref],
    }),
    [ctx, ref]
  );
}

export function useDashboardDispatch() {
  const ctx = useContext(DashboardDispatchContext);
  if (!ctx) {
    throw new Error("useDashboardDispatch must be used within a DashboardProvider");
  }
  return ctx;
}

function dashboardReducer(state: DashboardData, { type, payload }: DashboardAction): DashboardData {
  switch (type) {
    case "setTimeFrame":
      return setTimeFrame(state, payload);
    case "updateData":
      return updateData(state, payload);
    default:
      throw new Error("Invalid action type");
  }
}

function setTimeFrame(state: DashboardData, payload: SetTimeFramePayload): DashboardData {
  console.log("updating time frame", payload.timeFrameMin, state.timeFrameMin);
  const ok = intLocalStorage.add("timeFrame", payload.timeFrameMin);
  if (!ok) {
    console.error("Failed to set time frame in local storage");
    return state;
  }
  return {
    ...state,
    timeFrameMin: payload.timeFrameMin,
  };
}

function updateData(state: DashboardData, payload: Record<string, Datum[]>): DashboardData {
  return {
    ...state,
    data: {
      ...state.data,
      ...payload,
    },
  };
}
