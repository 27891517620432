import dayjs, { Dayjs } from "dayjs";
import { post, get } from "./api";
import costCenters from "../data/costCenters.json";
import { z } from "zod";

export enum DowntimeCategory {
  Mechanical = "Mechanical",
  Electrical = "Electrical",
  Instrumentation = "Instrumentation",
  Process = "Process",
  Other = "Other",
}

export type CreateDowntimeParams = {
  Reporter?: string;
  CostCenter?: string;
  LostSlice?: number;
  LostSugarProduction?: number;
  Category?: DowntimeCategory;
  Equipment?: string;
  Reason?: string;
  StartTime?: Dayjs;
  EndTime?: Dayjs;
};

const zodDay = z.custom<Dayjs>((val) => val instanceof dayjs, "Invalid date");

export const DowntimeSchema = z.object({
  Reporter: z.string().min(1),
  CostCenter: z.string(),
  LostSlice: z.number().nonnegative(),
  LostSugarProduction: z.number().nonnegative(),
  Category: z.nativeEnum(DowntimeCategory),
  Equipment: z.string().min(1),
  Reason: z.string().min(1),
  StartTime: zodDay,
  EndTime: zodDay.optional(),
})
  .refine(data => data.EndTime !== undefined ? data.EndTime.isAfter(data.StartTime) : true,
    { message: "End time must be after start time", path: ["EndTime"] });

export type Downtime = z.infer<typeof DowntimeSchema> & {
  id: number;
};


type DowntimeResponse = {
  id: number;
  Reporter: string;
  CostCenter: string;
  LostSlice: number;
  LostSugarProduction: number;
  Category: number;
  Equipment: string;
  Reason: string;
  StartTime: Dayjs;
  EndTime?: Dayjs;
}

export type CostCenter = {
  id: number;
  name: string;
}

const DowntimeCategoryMap: { [key in DowntimeCategory]: number } = {
  [DowntimeCategory.Mechanical]: 0,
  [DowntimeCategory.Electrical]: 1,
  [DowntimeCategory.Instrumentation]: 2,
  [DowntimeCategory.Process]: 3,
  [DowntimeCategory.Other]: 4,
};

export const DowntimeCategoryArray = Object.values(DowntimeCategory);


export function toEfFormat(date: Dayjs | undefined) {
  return date?.format("YYYY-MM-DDTHH:mm:ss");
}

export const createDowntime = async (params: CreateDowntimeParams) => {
  console.log(params);
  return await post("Downtime", {
    ...params,
    StartTime: toEfFormat(params.StartTime),
    EndTime: toEfFormat(params.EndTime),
    Category: params.Category ? DowntimeCategoryMap[params.Category] : undefined,
  });
};

export const listDowntimes = async (): Promise<Downtime[] | Error> => {
  const downtimes = await get<DowntimeResponse[]>("Downtime");
  if (downtimes instanceof Error) {
    return downtimes;
  }
  return downtimes.map(d => ({
    ...d,
    Category: DowntimeCategoryArray[d.Category],
  }));
};

export const listCostCenters = async (): Promise<CostCenter[] | Error> => {
  // hacked for now
  return Promise.resolve(
    costCenters as CostCenter[],
  );
};