import { createContext, ReactNode, useContext, useMemo } from "react";
import {
  convertSample,
  Sample,
  SampleFilter,
  SampleResponse,
  streamFilteredSamples,
} from "../handlers/samples";
import { useQuery } from "@tanstack/react-query";

export type AnalyteSamples = Record<string, Sample[]>;

export type LocationAnalytes = Record<string, AnalyteSamples>;

interface LabData {
  locations?: LocationAnalytes;
  filter: SampleFilter;
  isLoading: boolean;
  isError: boolean;
}

const LabDataContext = createContext<LabData | null>(null);

interface LabDataProviderProps {
  dataKey: string;
  filter: SampleFilter;
  children: ReactNode;
}

export function LabDataProvider({ children, filter, dataKey }: LabDataProviderProps) {
  const queryFunction = useMemo(() => queryFn(filter), [filter]);
  const { data, isLoading, isError } = useQuery({
    queryKey: [dataKey, JSON.stringify(filter)],
    queryFn: queryFunction,
  });
  return (
    <LabDataContext.Provider
      value={{
        locations: data,
        filter,
        isLoading,
        isError,
      }}
    >
      {children}
    </LabDataContext.Provider>
  );
}

export function useLabData(): LabData {
  const ctx = useContext(LabDataContext);
  if (!ctx) {
    throw new Error("useLabData must be used within a LabDataProvider");
  }
  return ctx;
}

function queryFn(filter: SampleFilter): () => Promise<LocationAnalytes> {
  return async () => {
    const samples = await streamFilteredSamples(filter);
    if (samples instanceof Error) {
      throw samples;
    }
    return samples.reduce(addToRecord, {});
  };
}

function addToRecord(acc: LocationAnalytes, resp: SampleResponse): LocationAnalytes {
  const analyteSamples = acc[resp.sampleLocation] || {};
  const sample = convertSample(resp);
  return {
    ...acc,
    [resp.sampleLocation]: {
      ...analyteSamples,
      [resp.analyte]: [...(analyteSamples[resp.analyte] || []), sample],
    },
  };
}
