import { createClient as createWSClient } from "graphql-ws";
import { cacheExchange, Client, fetchExchange, subscriptionExchange } from "urql";

const wsUrl = process.env.REACT_APP_GRAPHQL_SUB_ENDPOINT;
if (!wsUrl) {
  throw new Error("REACT_APP_GRAPHQL_SUB_ENDPOINT not set");
}
const gqlUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT;
if (!gqlUrl) {
  throw new Error("REACT_APP_GRAPHQL_ENDPOINT not set");
}
const authToken = process.env.REACT_APP_AUTH_TOKEN;
if (!authToken) {
  throw new Error("REACT_APP_AUTH_TOKEN not set");
}

const wsClient = createWSClient({
  url: wsUrl,
  connectionParams: {
    Authorization: `Bearer ${authToken}`,
  },
});

export const gqlClient = new Client({
  url: gqlUrl,
  exchanges: [
    cacheExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(request) {
        const input = { ...request, query: request.query || "" };
        return {
          subscribe(sink) {
            const unsubscribe = wsClient.subscribe(input, sink);
            return { unsubscribe };
          },
        };
      },
    }),
  ],
  fetchOptions: {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  },
});
