// COPYRIGHT VESL, LLC 2023
// CONFIDENTIAL DO NOT DISTRIBUTE WITHOUT EXPRESS PERMISSION OF VESL OWNERSHIP
/* ____________________________________________________________________________________________________
COPYRIGHT VESL ENGINEERING 2023
CONFIDENTIAL DO NOT DISTRIBUTE WITHOUT EXPRESS PERMISSION OF VESL OWNERSHIP
The purpose of this component is to render the filters as well as the graphs desired on the base UI.
Use of react components to render the pieces correctly
Use of D3 to create all plots
Use of Material UI and Bootstrap for basic Styling of objects.
_______________________________________________________________________________________________________*/

// VESL COMPONENT SPECIFIC CUSTOMIZATION / IMPORTS
// IN PROCESS remove import dataJSON to use api data
//    import dataJSON from "../data/dataJSON.json";
import React from "react";
import { Table, TableRow, TableCell, TableBody } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/index.js";
import "./Page.css";

function DailyOperatingTargets() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <div>
          <div>
            <div>
              <h1 className="filter-title">
                <strong>Daily Operating Targets</strong>
              </h1>
              <TableContainer component={Paper} className="narrow-table">
                <Table aria-label="simple table">
                  <TableRow align="center">
                    <TableCell align="center" colspan="2">
                      <strong>The Big 8</strong>
                    </TableCell>
                  </TableRow>
                  <TableBody align="center">
                    <TableRow>
                      <TableCell align="center">1. Maintain Target Slice and Draft</TableCell>
                      <TableCell align="center">2. Diffuser Supply Water Temp: 60-65 C</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        3. Consistent Carb pH & Alkalinity: 11.3/0.105
                      </TableCell>
                      <TableCell align="center">
                        4. Thick Juice/Standard Liquor Brix: 65-70
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5. High Raw Melter Brix: 65-70</TableCell>
                      <TableCell align="center">
                        6. High Green Purity: 85 (Syrup Separators)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">7. High Raw Pan Brix: 93-93.5</TableCell>
                      <TableCell align="center">8. Maintain Beet End Temps</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <TableContainer component={Paper} className="lab-sheet-table">
                <Table aria-label="simple table">
                  <TableRow align="center">
                    <TableCell align="center" colspan="2">
                      <strong>Operating Targets</strong>
                    </TableCell>
                  </TableRow>
                  <TableBody align="center">
                    <TableRow>
                      <TableCell align="center">Slice Targets</TableCell>
                      <TableCell align="center">130 tph</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Max Slice</TableCell>
                      <TableCell align="center">135 tph</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Draft</TableCell>
                      <TableCell align="center">120%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Pulp Loss</TableCell>
                      <TableCell align="center">1.9-2.2% sugar; 0.3-0.4%OB</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Diffuser Cell 5 Temp</TableCell>
                      <TableCell align="center">65&deg;C</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        Thin Juice SO<sub>2</sub>
                      </TableCell>
                      <TableCell align="center">100-150 ppm</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Thin Juice pH</TableCell>
                      <TableCell align="center">8.2-8.6</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Thin Juice RDS</TableCell>
                      <TableCell align="center">13-16</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Thin Juice Purity</TableCell>
                      <TableCell align="center">93-95</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Thin Juice Sugar Color</TableCell>
                      <TableCell align="center">1000-2500</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Thick Juice Purity</TableCell>
                      <TableCell align="center">93-95</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Thick Juice Sugar Color</TableCell>
                      <TableCell align="center">1000-2500</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">White Pan Drop Brix</TableCell>
                      <TableCell align="center">92.5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">White Pan pH</TableCell>
                      <TableCell align="center">8.8-9.2</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">White Pan RDS</TableCell>
                      <TableCell align="center">91.5-92</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">High Raw Pan Drop Brix</TableCell>
                      <TableCell align="center">93.5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">High Raw Pan RDS</TableCell>
                      <TableCell align="center">93-93.5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">High Raw Pan Purity</TableCell>
                      <TableCell align="center">85</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Low Raw Pan Drop Brix</TableCell>
                      <TableCell align="center">94.2</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Low Raw Pan RDS</TableCell>
                      <TableCell align="center">94-94.5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Low Raw Pan Purity</TableCell>
                      <TableCell align="center">73-75</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Standard Sugar Color</TableCell>
                      <TableCell align="center">3000-3500</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Melter Sugar Color</TableCell>
                      <TableCell align="center">3000-3500</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Standard Liquor RDS</TableCell>
                      <TableCell align="center">91.5-92</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Standard Liquor Purity</TableCell>
                      <TableCell align="center">93-93.5%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Molasses RDS</TableCell>
                      <TableCell align="center">82-84</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Molasses % Sugar</TableCell>
                      <TableCell align="center">48-54</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Molasses Purity</TableCell>
                      <TableCell align="center">{"<60"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Storage pH</TableCell>
                      <TableCell align="center">{">9"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Storage Purity</TableCell>
                      <TableCell align="center">90-93</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <TableContainer component={Paper} className="lab-sheet-table">
                <Table aria-label="simple table">
                  <TableRow align="center">
                    <TableCell align="center" colspan="2">
                      <strong>Beet End Operating Parameters</strong>
                    </TableCell>
                  </TableRow>
                  <TableBody align="center">
                    <TableRow>
                      <TableCell align="center">Cossette Purity</TableCell>
                      <TableCell align="center">87-89%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Cossette % Sugar</TableCell>
                      <TableCell align="center">17-19%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Raw Juice RDS</TableCell>
                      <TableCell align="center">13-16</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Raw Juice Purity</TableCell>
                      <TableCell align="center">89-91</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">MOL CaO</TableCell>
                      <TableCell align="center">10-12%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">MOL Brix</TableCell>
                      <TableCell align="center">30-33</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Pulp Water % Sugar</TableCell>
                      <TableCell align="center">1.9-2.2%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Sewer % Sugar</TableCell>
                      <TableCell align="center">0-0.5%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Lime Flume % Sugar</TableCell>
                      <TableCell align="center">0-3%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Prelimer 6 pH</TableCell>
                      <TableCell align="center">11-11.2</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Diffuser Supply pH</TableCell>
                      <TableCell align="center">4-6</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">PPW pH</TableCell>
                      <TableCell align="center">5-6</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <TableContainer component={Paper} className="lab-sheet-table">
                <Table aria-label="simple table">
                  <TableRow align="center">
                    <TableCell align="center" colspan="2">
                      <strong>Carb Operating Parameters</strong>
                    </TableCell>
                  </TableRow>
                  <TableBody align="center">
                    <TableRow>
                      <TableCell align="center">MOL CaO</TableCell>
                      <TableCell align="center">10-12%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Prelimer Alkalinity</TableCell>
                      <TableCell align="center">0.180-0.200 g/100mL</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Prelimer CaO</TableCell>
                      <TableCell align="center">0.9-1.4% CaO</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Enviroclear Underflow Rate</TableCell>
                      <TableCell align="center">10-12% on Raw Juice Flow</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Cold Limer pH</TableCell>
                      <TableCell align="center">11.8</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Hot limer pH</TableCell>
                      <TableCell align="center">12.2</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Hot Limer Alkalinity</TableCell>
                      <TableCell align="center">0.850-0.900 g/100mL</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Hot Limer CaO</TableCell>
                      <TableCell align="center">1.9-2.2% CaO</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">1st Carb Alakalinity</TableCell>
                      <TableCell align="center">0.100-0.115 g/100mL</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">1st Carb pH</TableCell>
                      <TableCell align="center">11.1-11.2</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2nd Carb Alkalinity</TableCell>
                      <TableCell align="center">0.014-0.016</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2nd Carb pH</TableCell>
                      <TableCell align="center">9.2-9.3</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default DailyOperatingTargets;
