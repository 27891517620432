import { DashboardProvider } from "context/dashboard";
import { TimeFramePicker } from "../../components/Dashboard/time-frame-picker";
import { Page } from "../../layouts/pages/Page";
import { newTag, TagUnit } from "../../model/tags";
import { Equipment } from "../../components/Dashboard/equipment";
import {  Provider } from "urql";
import React, { useMemo, useState } from "react";
import { ScrollArea, ScrollBar } from "../../components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Switch } from "../../components/ui/switch";
import { Label } from "../../components/ui/label";
import { intLocalStorage } from "../../lib/local-storage";
import { gqlClient } from "../../handlers/urql";

const initialTimeFrameMin = 60;
const units: TagUnit[] = [
  {
    name: "Slicer",
    equipment: [
      {
        name: "Slicers",
        tags: [newTag(40452, "sliceRate", "Slice rate (tons/hr)")],
      },
      {
        name: "Slice Scale",
        tags: [newTag(38719, "diffuserDraft", "Draft")],
      },
    ],
  },
  {
    name: "Lime Kiln",
    equipment: [
      {
        name: "Kiln",
        tags: [newTag(38259, "inSkipLevel", "Level (%)")],
      },
    ],
  },
  {
    name: "Carbonation",
    equipment: [
      {
        name: "Milk of Lime",
        tags: [newTag(39238, "molFlowHot", "Milk of Lime Flow - Hot (gpm)")],
      },
      {
        name: "Milk of Lime",
        tags: [newTag(39465, "molFlowCold", "Milk of Lime Flow - Cold (gpm)")],
      },
      {
        name: "Milk of Lime",
        tags: [newTag(39472, "molFlowHoward", "Milk of Lime Flow - Howard (gpm)")],
      },
      {
        name: "Milk of Lime",
        tags: [newTag(39223, "molDensity", "Density (Brix %)")],
      },
      {
        name: "First & Second Carbonation",
        tags: [newTag(39512, "co2GasPressure", "CO₂ Gas Pressure (psi)")],
      },
      // {
      //   name: "First & Second Carbonation",
      //   tags: [newTag(39487, "carbPh", "carb pH")],
      // },
    ],
  },
  {
    name: "Evaporation",
    equipment: [
      {
        name: "5th Evaporator",
        tags: [newTag(40085, "evapBrix", "Density (Brix %)")],
      },
    ],
  },
  {
    name: "Pans",
    equipment: [
      {
        name: "White Pan 1",
        tags: [newTag(36100, "wp1Density", "Density (Brix %)", "x / 100")],
      },
      {
        name: "White Pan 2",
        tags: [newTag(37527, "wp2Density", "Density (Brix %)", "x / 100")],
      },
      {
        name: "High Raw Pan",
        tags: [newTag(36891, "hrpDensity", "Density (Brix %)", "x / 100")],
      },
    ],
  },
  {
    name: "Thick juice",
    equipment: [
      {
        name: "Thick Juice Storage",
        tags: [newTag(40944, "juicyBrix", "Density (Brix %)")],
      },
    ],
  },
  // skipping until we can plot boolean data better
  // {
  //   name: "Boilers",
  //   equipment: [],
  // },
];

export function RealtimeDashboardPage() {
  const [showAll, setShowAll] = useState(initialShowAll());
  const initialTimeFrameInMins = useMemo(() => initialTimeFrame(), []);

  function onShowAllChange(value: boolean) {
    localStorage.setItem("showAll", value ? "true" : "false");
    setShowAll(value);
  }

  return (
    <Page>
      <Provider value={gqlClient}>
        <DashboardProvider initialTimeFrameMin={initialTimeFrameInMins} units={units}>
          <div className={"container mx-auto p-2"}>
            <h1 className={"text-2l font-bold mb-4"}>Process Dashboard</h1>
            <TimeFramePicker initialTimeFrameMin={initialTimeFrameInMins} />
            <div className={"space-x-0.5"}>
              <Switch id={"showAll"} checked={showAll} onCheckedChange={onShowAllChange} />
              <Label htmlFor={"showAll"} className={"text-sm items-center"}>
                Show All
              </Label>
            </div>
            {showAll ? <AllEquipment /> : <TabbedEquipment />}
          </div>
        </DashboardProvider>
      </Provider>
    </Page>
  );
}

function AllEquipment() {
  return (
    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"}>
      {units.map((unit) =>
        unit.equipment.map((equipment) => <Equipment key={equipment.name} {...equipment} />)
      )}
    </div>
  );
}

function TabbedEquipment() {
  return (
    <Tabs className={"w-full"} defaultValue={units[0]?.name}>
      <ScrollArea className={"flex w-full rounded-md border overflow-auto"}>
        <div className={"flex w-max space-x-4 p-2"}>
          <TabsList>
            {units.map((unit) => (
              <TabsTrigger key={unit.name} value={unit.name} className={"flex-shrink-0"}>
                {unit.name}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
        <ScrollBar orientation={"horizontal"} />
      </ScrollArea>
      {units.map((unit) => (
        <TabsContent key={unit.name} value={unit.name}>
          <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"}>
            {unit.equipment.map((equipment) => (
              <Equipment key={equipment.name} {...equipment} />
            ))}
          </div>
        </TabsContent>
      ))}
    </Tabs>
  );
}

// initialTimeFrame checks local storage for the initial time frame and if its there, returns it, otherwise returns the default
// and sets it in local storage as a side effect.
function initialTimeFrame(): number {
  const timeFrame = intLocalStorage.get("timeFrame");
  if (!timeFrame) {
    console.log("time frame not found in local storage, setting to default value");
    intLocalStorage.add("timeFrame", initialTimeFrameMin);
    return initialTimeFrameMin;
  }
  return timeFrame;
}

function initialShowAll(): boolean {
  const showAll = localStorage.getItem("showAll");
  if (showAll) {
    return showAll === "true";
  }
  localStorage.setItem("showAll", "false");
  return false;
}
