import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

type DataTableProps<Type> = {
  columns: GridColDef[];
  rows: Type[];
  enableToolbar?: boolean;
  sx?: object;
};

function DataGridTable<Type>({ columns, rows, enableToolbar, sx }: DataTableProps<Type>) {
  return (
    <DataGrid
      columns={columns}
      rows={rows}
      slots={{ toolbar: enableToolbar ? GridToolbar : null }}
      sx={sx}
    />
  );
}

export default DataGridTable;
