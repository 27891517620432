import {
  Column,
  ColumnDef,
  ColumnPinningState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  useReactTable,
} from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";

import { CSSProperties, useEffect, useState } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { ArrowUpDown } from "lucide-react";
import { DataTablePagination } from "./DataTablePagination";

function getCommonPinningStyles<TData>(column: Column<TData>): CSSProperties {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-2px 0 2px -2px gray inset"
      : isFirstRightPinnedColumn
      ? "2px 0 2px -2px gray inset"
      : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    textAlign: "left",
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  };
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  columnPinning?: ColumnPinningState;
  paginated?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  columnPinning,
  paginated,
}: DataTableProps<TData, TValue>) {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const table = useReactTable({
    data,
    columns,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: paginated ? getPaginationRowModel() : undefined,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    initialState: columnPinning ? { columnPinning } : undefined,
    autoResetPageIndex: false,
  });
  return (
    <div className={"overflow-x-auto"}>
      <Table className={"w-full table-auto"}>
        <TableHeader className={"bg-gray-100 m-0 p-1 sticky top-0 z-10"}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className={"bg-muted/50"}>
              {headerGroup.headers.map((header) => (
                <HeaderCell key={header.id} header={header} columnPinning={columnPinning} />
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className={"bg-gray-100 max-h-[calc(100vh-200px)] overflow-y-auto"}>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    style={{ ...getCommonPinningStyles(cell.column) }}
                    className={"whitespace-nowrap bg-gray-100 text-xs m-0 px-2 py-1.5"}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>No data.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {paginated ? <DataTablePagination table={table} /> : null}
    </div>
  );
}

interface HeaderCellProps<TData, TContext> {
  header: Header<TData, TContext>;
  columnPinning?: ColumnPinningState;
}

function HeaderCell<TData, TContext>({ header, columnPinning }: HeaderCellProps<TData, TContext>) {
  return (
    <TableHead
      key={header.id}
      style={columnPinning !== undefined ? getCommonPinningStyles(header.column) : {}}
      className={"whitespace-nowrap bg-gray-300 text-left m-0 px-2 text-xs h-full py-1.5"}
    >
      <HeaderCellContent header={header} />
    </TableHead>
  );
}

interface HeaderCellContentProps<TData, TContext> {
  header: Header<TData, TContext>;
}

function HeaderCellContent<TData, TContext>({ header }: HeaderCellContentProps<TData, TContext>) {
  if (header.isPlaceholder) {
    return null;
  }
  if (!header.column.columnDef.enableSorting && !header.column.getCanFilter()) {
    return flexRender(header.column.columnDef.header, header.getContext());
  }
  if (!header.column.getCanFilter()) {
    return <SortableHeaderCell header={header} />;
  }
  return (
    <>
      <SortableHeaderCell header={header} />
      <Filter column={header.column} />
    </>
  );
}

interface SortableHeaderCellProps<TData, TContext> {
  header: Header<TData, TContext>;
}

function SortableHeaderCell<TData, TContext>({ header }: SortableHeaderCellProps<TData, TContext>) {
  function handleClick() {
    header.column.toggleSorting(header.column.getIsSorted() === "asc");
  }

  return (
    <Button variant="ghost" onClick={handleClick}>
      {flexRender(header.column.columnDef.header, header.getContext())}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

interface FilterProps<TData> {
  column: Column<TData>;
}

function Filter<TData>({ column }: FilterProps<TData>) {
  const filterValue = column.getFilterValue();
  return (
    <DebouncedInput
      className="w-36 rounded border shadow"
      onChange={(value) => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(filterValue ?? "") as string}
    />
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 100,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return <Input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}
