import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

type FormDateFieldProps = {
  label: string;
  value?: Dayjs;
  onChange?: (value: Dayjs | null) => void;
  defaultValue?: Dayjs;
  error?: boolean;
  helperText?: string;
  required?: boolean;
};

const FormDateField = ({ label, required, value, onChange, defaultValue, error, helperText }: FormDateFieldProps) => {
  return (
    <>
      <DateTimePicker
        value={value}
        onChange={onChange}
        label={`${label}${required ? "*" : ""} `}
        defaultValue={defaultValue}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
      />
      {error && <span style={
        {
          color: "red",
          fontSize: "0.75rem",
          display: "block",
          marginTop: "0.5rem",
        }
      }>{helperText}</span>}
    </>
  );
};

export default FormDateField;
