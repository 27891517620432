import { TagEquipment } from "../../model/tags";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Tag } from "./tag";

export function Equipment({ name, tags }: TagEquipment) {
  return (
    <Card className={"w-full"}>
      <CardHeader className={"p-2"}>
        <CardTitle className={"text-lg sm:text-xl"}>{name}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={""}>
          {tags.map((tag) => (
            <Tag key={tag.reference} {...tag} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
