import { LabDataDashboardPage } from "./index";
import { SampleFilter } from "../../handlers/samples";
import dayjs from "dayjs";
import { useMemo } from "react";
import UnitOp2SampleLoc from "../../layouts/dashboards/LabDash/UnitOp2SampleLoc";
import ProcessLoc2UnitOp from "../../layouts/dashboards/LabDash/ProcessLoc2UnitOp";

interface UnitOpPageProps {
  initialUnitOp: string;
  initialProcessLocation: string;
}

export function UnitOpPage(props: UnitOpPageProps) {
  const filter = useMemo(() => toFilter(props), [props]);
  return <LabDataDashboardPage dataKey={props.initialProcessLocation} initialFilter={filter} />;
}

interface ProcessLookup {
  unitOps: string[];
}

interface UnitOpLookup {
  sampleLocations: string[];
}

export function toFilter({ initialUnitOp, initialProcessLocation }: UnitOpPageProps): SampleFilter {
  const base = {
    startDate: dayjs().subtract(1, "day"),
    endDate: dayjs(),
  };
  const unitOps =
    initialUnitOp == "None" || !(initialUnitOp in UnitOp2SampleLoc) ? [] : [initialUnitOp];
  if (initialProcessLocation in ProcessLoc2UnitOp) {
    const processOps = (ProcessLoc2UnitOp as Record<string, ProcessLookup>)[initialProcessLocation]
      .unitOps;
    unitOps.push(...processOps);
  }
  const sampleLocations = unitOps.flatMap((unitOp) =>
    unitOp in UnitOp2SampleLoc
      ? (UnitOp2SampleLoc as Record<string, UnitOpLookup>)[unitOp].sampleLocations
      : []
  );
  return { ...base, sampleLocations, analytes: [] };
}
