import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DowntimeForm from "../../components/Downtime/DowntimeForm";
import Typography from "@mui/material/Typography";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import React from "react";


const CreateDowntimePage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Typography variant={"h4"}>New Downtime</Typography>
      <DowntimeForm variant={"standard"} />
    </DashboardLayout>
  );
};

export default CreateDowntimePage;