import { Referenced } from "../../model/tags";
import { Plot } from "./plot";

export function Tag(props: Referenced) {
  return (
    <div className={"w-full "}>
      <h3 className={"text-2m font-medium mb-2"}>{props.displayName}</h3>
      <Plot {...props} />
    </div>
  );
}
