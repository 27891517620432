const UnitOp2SampleLoc = {
  "2nd Carb Filters": {
    sampleLocations: ["2nd Carb", "2nd Carb Filter"],
  },
  "Carb Gassing": {
    sampleLocations: ["1st Carb-LAB", "1st Carbonation", "1st Carb-STA", "1st Filter"],
  },
  "Carb Liming": {
    sampleLocations: ["1st Carb"],
  },
  Clarifier: {
    sampleLocations: ["Dorr Clarifier", "Waste Clarifier"],
  },
  Concentrator: {
    sampleLocations: ["Conc Leg"],
  },
  "Conditioning Bits": {
    sampleLocations: ["Dry S #1", "Dry S #2"],
  },
  "Cooling Tower": {
    sampleLocations: ["Cooling Tower"],
  },
  Crystalizer: {
    sampleLocations: ["To Crystallizer", "Fr Crystallizer", "Main House Wtr", "Main Sewer"],
  },
  Diffuser: {
    sampleLocations: [
      "Diff #1 Battery",
      "Diff Supl Water",
      "Diff#1",
      "Diff#1 Cossette",
      "Diff#1 Knives",
      "Diff#1 Max Batt",
      "Diff#1 Merrick",
      "Diff#1 PPW",
      "Diff#1 Raw Jce",
      "Diff#1 RJ Batt",
      "Diff#1 RJ Cell",
      "Diff#1 Slice",
    ],
  },
  Evaporators: {
    sampleLocations: [
      "Boiler Direct",
      "Boiler E Tk",
      "Boiler Feed",
      "Boiler Fgas",
      "Boiler Tank",
      "Boiler W Tk",
      "Evap #5",
      "Evap Last Eff-A",
      "Evap Thick Jce",
      "Thin Juice",
      "To Juice Evap-A",
    ],
  },
  "High Raw Pans": {
    sampleLocations: [
      "Hi Raw",
      "Hi Raw Fillmass",
      "Hi Raw Melter",
      "Hi Raw Pan",
      "Hi Raw Storage",
      "Hi Raw Wwat",
      "Pan Class",
    ],
  },
  "Lime Kiln": {
    sampleLocations: [
      "Boiler #1 Bl Dn",
      "Boiler #1 Water",
      "Discard from",
      "Kiln #1 Lime Pr",
      "Lime Cake",
      "Lime Flume",
      "Lime Kiln Gas",
      "Lime Purchased",
      "Limerock to",
      "MainLimer",
      "Milk of Lime",
      "Mingle",
      "Mol Prod",
      "Mol Prod 24Hr",
      "Mol Produced",
      "Mol to Drier",
      "Prelimer",
      "Prelimer C#1",
      "Prelimer C#2",
      "Prelimer C#3",
      "Prelimer C#4",
      "Prelimer C#5",
      "Prelimer C#6",
      "Purchased Lime",
      "To Prelimer",
    ],
  },
  "Low Raw Pans": {
    sampleLocations: [
      "Aff Fillmass",
      "Affination",
      "Affination Syr",
      "Low Raw Wwat",
      "Low Raw",
      "Low Raw 24Hr",
      "Low Raw A",
      "Low Raw B",
      "Low Raw Melter",
      "Low Raw Storage",
      "Low Raw Wwat",
      "LR to Cryst",
    ],
  },
  "Pulp Presses": {
    sampleLocations: [
      "Dr Pulp Sold",
      "Dried Pulp",
      "Pr Pulp Sold",
      "Pr Pulp Water",
      "Pulp from Press",
      "Pulp Presses",
    ],
  },
  Slicers: {
    sampleLocations: ["Beet Flume", "Cossette"],
  },
  "Std Liquor Filters": {
    sampleLocations: ["Std Liquor", "To Std Liq Filt"],
  },
  "Thick juice Stor": {
    sampleLocations: ["Raw Jce Exit", "Thick Juice", "Thk Juice fr Stor", "Thk Jce to Stor"],
  },
  "White Raw Pans": {
    sampleLocations: [
      "White A",
      "White B",
      "White Fillmass",
      "White Pan",
      "White Pan #1",
      "White Pan #2",
      "White Sugar",
      "White Sugar #1",
      "White Sugar #2",
    ],
  },
  "White Centrifuge": {
    sampleLocations: ["Wet S #1", "Wet S #2"],
  },
  all: {
    sampleLocations: [
      "1st Carb",
      "2nd Carb",
      "Aff Fillmass",
      "Affination Syr",
      "Cooling Tower",
      "Cossette",
      "Diff Supply Water",
      "Down Thin Juice",
      "Evap Thick Juice",
      "Hi Raw Melter",
      "Hi Raw Pan",
      "Hi Raw Storage",
      "Lime Flume",
      "Low Raw",
      "Low Raw Storage",
      "Main Sewer",
      "Milk of Lime",
      "Mol Produced",
      "Pr Pulp Water",
      "PreLimer C#1",
      "PreLimer C#2",
      "PreLimer C#3",
      "PreLimer C#4",
      "PreLimer C#5",
      "PreLimer C#6",
      "Raw Jce Exit",
      "Seal Tank",
      "Std Liquor",
      "Sug Produced",
      "Thk Jce fr Stor",
      "White Pan",
      "White Sugar",
      "1st Carb-LAB",
      "1st Carbonation",
      "1st Carb-STA",
      "2nd Carb Filter",
      "Affination",
      "Battery Supply",
      "Beet Flume",
      "Boiler #1 Bl Dn",
      "Boiler #1 Water",
      "Boiler Direct",
      "Boiler E Tk",
      "Boiler Feed",
      "Boiler Fgas",
      "Boiler Tank",
      "Boiler W Tk",
      "Clarifier",
      "Conc Leg",
      "Diff #1 Battery",
      "Diff Supl Water",
      "Diff#1",
      "Diff#1 Cossette",
      "Diff#1 Knives",
      "Diff#1 Max Batt",
      "Diff#1 Merrick",
      "Diff#1 PPW",
      "Diff#1 Raw Jce",
      "Diff#1 RJ Batt",
      "Diff#1 RJ Cell",
      "Diff#1 Slice",
      "Discard from",
      "Dorr Clarifier",
      "Dr #1 Exit",
      "Dr #1 Furnace",
      "Dr Pulp Sold",
      "Dried Pulp",
      "Dry S #1",
      "Dry S #2",
      "Dry Sugar Sak",
      "Dummy",
      "Evap #5",
      "Evap Last Eff-A",
      "Fr Crysallizer",
      "From Dorr",
      "Hi Raw",
      "Hi Raw Fillmass",
      "Hi Raw Wwat",
      "Kiln #1 Lime Pr",
      "Lime Cake",
      "Lime Kiln Gas",
      "Lime Purchased",
      "Limerock to",
      "Lo Raw Wwat",
      "Low Raw 24Hr",
      "Low Raw A",
      "Low Raw B",
      "Low Raw Melter",
      "Low Raw Wwat",
      "LR To Cryst",
      "Main House Wtr",
      "MainLimer",
      "Mingler",
      "Mol Prod",
      "Mol Prod 24Hr",
      "Mol to Drier",
      "O.L. Cond",
      "Pan Class",
      "Pellet Prod",
      "Pellets Sold",
      "Pr Pulp Sold",
      "PreLimer",
      "Pulp from Press",
      "Pulp Presses",
      "Purchased Lime",
      "River",
      "Rock Returned",
      "Screen Test",
      "Spalls",
      "Tare Lab +C",
      "Tare Lab -C",
      "Tare Lab ML",
      "Tare Lab TA",
      "Tare Lab TL",
      "Thick Juice",
      "Thk Jce to Stor",
      "Thin Juice",
      "To Crystallizer",
      "To Dorr",
      "To Juice Evap-A",
      "To Prelimer",
      "To Std Liq Filt",
      "Wash Water",
      "Waste Clarifier",
      "Wet S #1",
      "Wet S #2",
      "White A",
      "White B",
      "White Fillmass",
      "White Pan #1",
      "White Pan #2",
      "White Sugar #1",
      "White Sugar #2",
      "None",
    ],
  },
};

export default UnitOp2SampleLoc;
