import { useDashboardDispatch } from "../../context/dashboard";
import { ChangeEvent, useCallback, useState } from "react";
import { Label } from "../ui/label";
import { Slider } from "../ui/slider";
import { Input } from "../ui/input";
import { useDebouncedCallback } from "use-debounce";

interface TimeFramePickerProps {
  initialTimeFrameMin?: number;
}

export function TimeFramePicker({ initialTimeFrameMin = 60 }: TimeFramePickerProps) {
  const dispatch = useDashboardDispatch();
  const [timeFrame, setTimeFrame] = useState(initialTimeFrameMin);
  const debouncedDispatch = useDebouncedCallback((newValue: number) => {
    dispatch({
      type: "setTimeFrame",
      payload: {
        timeFrameMin: newValue,
      },
    });
  }, 500);

  const onTimeFrameChange = useCallback(
    (newValue: number) => {
      if (isNaN(newValue)) {
        return;
      }
      setTimeFrame(newValue);
      debouncedDispatch(newValue);
    },
    [debouncedDispatch]
  );

  function formatTimeFrame(minutes: number) {
    if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? "s" : ""}`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} hr${hours !== 1 ? "s" : ""}${
        remainingMinutes > 0 ? ` ${remainingMinutes} min${remainingMinutes !== 1 ? "s" : ""}` : ""
      }`;
    }
  }

  return (
    <div className={"space-y-4"}>
      <div className={"space-y-2"}>
        <Label htmlFor={"time-frame-slider"}>Time Frame: {formatTimeFrame(timeFrame)}</Label>
        <Slider
          id={"time-frame-slider"}
          min={1}
          max={1440}
          step={1}
          value={[timeFrame]}
          onValueChange={(values) => {
            onTimeFrameChange(values[0]);
          }}
        />
      </div>
      <div className={"flex items-center space-x-2"}>
        <Label htmlFor={"time-frame-input"}>Time Frame (minutes):</Label>
        <Input
          id={"time-frame-input"}
          type={"string"}
          min={1}
          max={1440}
          value={timeFrame}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onTimeFrameChange(parseInt(e.target.value, 10));
          }}
          className={"w-24"}
        />
      </div>
    </div>
  );
}
