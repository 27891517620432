import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { LabDataPlot } from "./plot/plot";
import { LabDataTable } from "./table";

export function TabView() {
  return (
    <Tabs defaultValue={"plot"}>
      <TabsList>
        <TabsTrigger value={"plot"}>Plot View</TabsTrigger>
        <TabsTrigger value={"table"}>Table View</TabsTrigger>
      </TabsList>
      <TabsContent value={"plot"}>
        <LabDataPlot />
      </TabsContent>
      <TabsContent value={"table"}>
        <LabDataTable />
      </TabsContent>
    </Tabs>
  );
}
