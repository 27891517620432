import { LocationAnalytes, useLabData } from "../../../context/lab-data";
import { ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ChartDatum, ChartSeries, PlotFilterOptions } from "./props";
import { PlotFilter } from "./plot-filter";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

export function LabDataPlot() {
  const { locations, filter } = useLabData();
  const [plotFilter, setPlotFilter] = useState<PlotFilterOptions>({
    hiddenAnalytes: [],
    hiddenLocations: [],
  });
  const chartData = useMemo(
    () => toChartSeriesArray(locations, plotFilter),
    [locations, plotFilter]
  );
  return (
    <div className={"w-full h-full"}>
      <div className={"w-full h-96 md:h-[800px]"}>
        <ResponsiveContainer width={"100%"} height={"100%"} className={"p-1"}>
          <ScatterChart>
            <XAxis
              dataKey={"time"}
              type={"number"}
              tickFormatter={(timestamp) =>
                dayjs.unix(timestamp).local().format("DD MMM YYYY hh:mm a")
              }
              tick={{ fontSize: 12 }}
              domain={
                filter.startDate && filter.endDate
                  ? [filter.startDate.unix(), filter.endDate.unix()]
                  : undefined
              }
            />
            <YAxis
              type={"number"}
              dataKey={"value"}
              tick={{ fontSize: 12 }}
              label={{
                value: "Value",
                angle: -90,
                position: "insideLeft",
                fontSize: 14,
              }}
            />
            <Tooltip
              content={({ payload }) => {
                if (!payload) {
                  return null;
                }
                if (payload.length === 0) {
                  return null;
                }
                const datum = payload[0].payload as ChartDatum;
                if (!datum) {
                  return null;
                }
                return (
                  <div className={"bg-gray-400 bg-opacity-50 rounded-lg p-1"}>
                    <table className={"table-auto"}>
                      <tbody>
                        <tr className={"space-x-2"}>
                          <th className={"font-bold text-end"}>Time</th>
                          <td className={"text-start"}>
                            {dayjs.unix(datum.time).utc().local().format("DD MMM YYYY hh:mm a")}
                          </td>
                        </tr>
                        <tr className={"space-x-2"}>
                          <th className={"font-bold text-end"}>Location</th>
                          <td className={"text-start"}>{datum.label.split("/")[0]}</td>
                        </tr>
                        <tr className={"space-x-2"}>
                          <th className={"font-bold text-end"}>Sample</th>
                          <td className={"text-start"}>{datum.label.split("/")[1]}</td>
                        </tr>
                        <tr className={"space-x-2"}>
                          <th className={"font-bold text-end"}>Value</th>
                          <td className={"text-start"}>{datum.value}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              }}
            />
            {Object.entries(chartData).map(([key, { hue, data }]) => (
              <Scatter
                key={key}
                name={key}
                data={data}
                fill={hue}
                isAnimationActive={false}
                hide={isHidden(plotFilter, key)}
              />
            ))}
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <PlotFilter plotFilterOptions={plotFilter} setPlotFilterOptions={setPlotFilter} />
    </div>
  );
}

function toChartSeriesArray(
  locations?: LocationAnalytes,
  filterOptions?: PlotFilterOptions
): Record<string, ChartSeries> {
  if (!locations) {
    return {};
  }
  const colors = makeHueIterator();

  return Object.entries(locations).reduce((acc, [location, analytes]) => {
    if (filterOptions && filterOptions.hiddenLocations.includes(location)) {
      return acc;
    }
    const locationAnalytes = Object.entries(analytes).reduce((analyteAcc, [analyte, samples]) => {
      if (filterOptions && filterOptions.hiddenAnalytes.includes(analyte)) {
        return analyteAcc;
      }
      return {
        ...analyteAcc,
        [`${location}/${analyte}`]: {
          hue: colors.next(),
          data: samples.map((sample) => {
            return {
              time: dayjs.utc(sample.collectDT).unix(),
              value: sample.resultValue,
              label: `${location}/${analyte}`,
            };
          }),
        },
      };
    }, {} as Record<string, ChartSeries>);
    return { ...acc, ...locationAnalytes };
  }, {});
}

function makeHueIterator() {
  let index = 0;
  return {
    next() {
      const hue = hues[index];
      index = (index + 1) % hues.length;
      return hue;
    },
  };
}

function isHidden(filterOptions: PlotFilterOptions, key: string): boolean {
  const [location, analyte] = key.split("/");
  return (
    filterOptions.hiddenLocations.includes(location) ||
    filterOptions.hiddenAnalytes.includes(analyte)
  );
}

const hues = [
  "#0173B2",
  "#DE8F05",
  "#029E73",
  "#D55E00",
  "#CC78BC",
  "#CA9161",
  "#FBAFE4",
  "#949494",
  "#ECE133",
  "#56B4E9",
];
