import sampleLocAnalyteLimit from "../data/sampleLocToAnalyte.json";
import { post, get, del } from "./api";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export type SampleRow = {
  sampleLocation: string;
  analyte: string;
  sampleTestDT: string;
  resultValue: number;
  upperLimit: number;
  lowerLimit: number;
};

function validRow(row: SampleRow) {
  return (
    row.sampleLocation &&
    row.analyte &&
    row.sampleTestDT &&
    row.resultValue &&
    row.resultValue >= row.lowerLimit &&
    row.resultValue <= row.upperLimit
  );
}

type CreateSampleRequest = {
  SampleLocation: string;
  Analyte: string;
  CollectDT: string;
  SampleTestDT: string;
  Uom: string;
  ResultValue: number;
};

export async function createSample(data: CreateSampleRequest) {
  return await post("Samples", data);
}

const rowToRequest = (row: SampleRow): CreateSampleRequest => {
  return {
    SampleLocation: row.sampleLocation,
    Analyte: row.analyte,
    CollectDT: row.sampleTestDT,
    SampleTestDT: row.sampleTestDT,
    Uom: "Units",
    ResultValue: row.resultValue,
  };
};

export const createSamples = async (rows: SampleRow[]) => {
  const validRows = rows.filter(validRow);
  const requests = validRows.map(rowToRequest);
  await post("Samples/Multiple", requests);
};

export type Sample = {
  id: number;
  sampleLocation: string;
  analyte: string;
  collectDT: Date;
  sampleTestDT: Date;
  resultValue: number;
};

export type SampleResponse = {
  id: number;
  sampleLocation: string;
  analyte: string;
  collectDT: string;
  sampleTestDT: string;
  resultValue: number;
};

export type SampleFilter = {
  sampleLocation?: string;
  sampleLocations?: string[];
  analyte?: string;
  analytes?: string[];
  startDate?: Dayjs;
  endDate?: Dayjs;
};

export const convertSample = (sample: SampleResponse): Sample => {
  return {
    id: sample.id,
    sampleLocation: sample.sampleLocation,
    analyte: sample.analyte,
    collectDT: dayjs.utc(sample.collectDT).toDate(),
    sampleTestDT: dayjs.utc(sample.sampleTestDT).toDate(),
    resultValue: sample.resultValue,
  };
};

const filterToRecord = (filter: SampleFilter): Record<string, string> => {
  const record: Record<string, string> = {};
  if (filter.sampleLocation) {
    record.sampleLocation = filter.sampleLocation;
  }
  if (filter.sampleLocations) {
    record.sampleLocations = filter.sampleLocations.join(",");
  }
  if (filter.analyte) {
    record.analyte = filter.analyte;
  }
  if (filter.analytes) {
    record.analytes = filter.analytes.join(",");
  }
  if (filter.startDate) {
    record.startDate = filter.startDate.toISOString();
  }
  if (filter.endDate) {
    record.endDate = filter.endDate.toISOString();
  }
  return record;
};

export const streamFilteredSamples = async (
  filter: SampleFilter
): Promise<SampleResponse[] | Error> => {
  return await get<SampleResponse[]>("Samples/FilteredSamples", filterToRecord(filter));
};

export const getLimits = (sampleLocation: string, analyte: string) => {
  const locationObj = sampleLocAnalyteLimit.find((loc) => loc.name === sampleLocation);
  if (!locationObj) return { upperLimit: 100, lowerLimit: 0 };

  const analyteObj = locationObj.children.find((child) => child.name === analyte);
  if (!analyteObj || analyteObj.children.length === 0) return { upperLimit: 100, lowerLimit: 0 };

  const upperLimit = analyteObj.children.find((child) => child.name === "Upper Limit")?.value;
  const lowerLimit = analyteObj.children.find((child) => child.name === "Lower Limit")?.value;
  if (!upperLimit || !lowerLimit) return { upperLimit: 100, lowerLimit: 0 };
  return {
    upperLimit: upperLimit,
    lowerLimit: lowerLimit,
  };
};

export const getAnalyteOptions = (sampleLocation: string) => {
  const location = sampleLocAnalyteLimit.find((loc) => loc.name === sampleLocation);
  return location ? location.children.map((child) => child.name) : [];
};

export function analyteOptions() {
  const allAnalytes = sampleLocAnalyteLimit.flatMap((loc) =>
    loc.children.map((child) => child.name)
  );
  const uniqueAnalytes = Array.from(new Set(allAnalytes));
  return uniqueAnalytes.sort();
}

export async function deleteSample({ id }: Pick<Sample, "id">) {
  return await del(`Samples/${id}`);
}
