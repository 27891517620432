import analyteLimits from "../data/analyteLimits.json";

type Limits = {
  upperLimit: number;
  lowerLimit: number;
};

const analytes: Record<string, Limits> = analyteLimits;


export function getAnalyteLimits(analyte: string): Limits {
  return analytes[analyte];
}
