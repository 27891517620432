import { SampleFilter } from "../../handlers/samples";
import { LabDataProvider } from "../../context/lab-data";
import { Page } from "../../layouts/pages/Page";
import { FilterToolbar } from "./filter-toolbar";
import { useState } from "react";
import { TabView } from "./tab-view";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

interface LabDataDashboardPageProps {
  dataKey?: string;
  initialFilter?: SampleFilter;
}

export function LabDataDashboardPage({ initialFilter, dataKey }: LabDataDashboardPageProps) {
  const [filter, setFilter] = useState<SampleFilter>(
    initialFilter || {
      startDate: dayjs().subtract(1, "day"),
      endDate: dayjs(),
    }
  );
  return (
    <Page>
      <FilterToolbar
        filter={filter}
        setFilter={setFilter}
        defaultOpen={initialFilter === undefined}
      />
      <LabDataProvider key={dataKey || "labData"} filter={filter} dataKey={dataKey || "labData"}>
        <TabView />
      </LabDataProvider>
    </Page>
  );
}
