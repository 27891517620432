import React from "react";
import { useMsal } from "@azure/msal-react";

export const SignOutButton = () => {
  let _postLogoutRedirectUri = "/signout-oidc";
  let _mainWindowRedirectUri = "/signout-oidc";

  const { instance } = useMsal();
  const handleLogout = async (logoutMethod) => {
    if (logoutMethod === "popup") {
      await instance.logoutPopup({
        postLogoutRedirectUri: _postLogoutRedirectUri,
        mainWindowRedirectUri: _mainWindowRedirectUri,
      });
      return;
    }
    if (logoutMethod === "redirect") {
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
    <>
      <button className="sign-button" onClick={() => handleLogout("redirect")}>
        Sign Out
      </button>
    </>
  );
};
