import Box from "@mui/material/Box";
import { Input } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useState } from "react";

export default function AddRows({ addRows, rowData, disabled }) {
  const [nRows, setNRows] = useState(1);

  const handleAddRows = () => {

    addRows(nRows, rowData);
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body1">Create</Typography>
        <Input
          type="number"
          value={nRows}
          onChange={(e) => setNRows(e.target.value)}
          style={{ width: "50px", margin: "10px", justifyContent: "right" }}
        />
        <Typography variant="body1">rows</Typography>
        <Button onClick={handleAddRows} disabled={disabled}>Create rows</Button>
      </Box>
    </>
  );
}
