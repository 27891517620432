import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import LabDashNavBar from "../../examples/Navbars/DashboardNavbar";
import { ReactNode } from "react";
import Footer from "../../components/Footer/Footer";

type PageProps = {
  children: ReactNode;
};

export const Page = ({ children }: PageProps) => {
  return (
    <DashboardLayout>
      <div className={"w-full"}>
        <LabDashNavBar />
        {children}
      </div>
      <Footer />
    </DashboardLayout>
  );
};
