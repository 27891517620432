import { LabSheetPage } from "./LabSheetPage";

const analytes = [
  "pH",
  "Purity",
  "RDS",
  "Color",
];

const sampleLocations = [
  "Evap Thick Juice",
  "Hi Raw Melter",
  "Std Liquor",
  "Hi Raw Storage",
  "Low Raw Storage",
  "Mol Produced",
];

const rows: [string, string][] = [
  ["Evap Thick Juice", "pH"],
  ["Evap Thick Juice", "Purity"],
  ["Evap Thick Juice", "RDS"],
  ["Evap Thick Juice", "Color"],
  ["Hi Raw Melter", "pH"],
  ["Hi Raw Melter", "Purity"],
  ["Hi Raw Melter", "RDS"],
  ["Hi Raw Melter", "Color"],
  ["Std Liquor", "pH"],
  ["Std Liquor", "Purity"],
  ["Std Liquor", "RDS"],
  ["Std Liquor", "Color"],
  ["Hi Raw Storage", "Purity"],
  ["Hi Raw Storage", "RDS"],
  ["Low Raw Storage", "Purity"],
  ["Low Raw Storage", "RDS"],
  ["Mol Produced", "pH"],
  ["Mol Produced", "Purity"],
  ["Mol Produced", "RDS"],
];


export const SugarEndPage = () => {
  return <LabSheetPage sampleLocations={sampleLocations} analytes={analytes} rows={rows} />;
};
