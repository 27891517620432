export interface LocalStorage<T> {
  add(key: string, value: T): boolean;

  get(key: string): T | null;
}

export const intLocalStorage: LocalStorage<number> = {
  add(key: string, value: number) {
    const isNan = isNaN(value);
    if (isNan) {
      return false;
    }
    localStorage.setItem(key, value.toString());
    return true;
  },
  get(key: string): number | null {
    const value = localStorage.getItem(key);
    if (value === null) {
      return null;
    }
    const parsed = parseInt(value, 10);
    if (isNaN(parsed)) {
      localStorage.removeItem(key);
      return null;
    }
    return parsed;
  },
};
